import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from "@/views/HomeView.vue"
import NoPermissionView from "@/views/NoPermissionView.vue"

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    redirect:{name:'building'}
  },
  {
    path: '/no-permission',
    name: 'no-permission',
    component: NoPermissionView,
  },
  {
    path: '/gdpr',
    name: 'gdpr',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Gdpr.vue')
  },
  {
    path: '/infocenter',
    name: 'infoCenter',
    component: () => import(/* webpackChunkName: "about" */ '../components/InfoCenter.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/buildings',
    name: 'building',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/Building.vue'),
   
  },
  {
    //path: '/building/:building_id/poicategories/:category_id/pois',
    path: '/building/:building_id/:category_id/pois',
    name: 'poisList',
    component: () => import('../components/PoisList.vue')
  },
  {
    //path: '/building/:building_id/poicategories/:category_id/pois',
    path: '/menu/:building_id/room_control/categories',
    name: 'roomControlCategories',
    component: () => import('../components/RoomControlCategories.vue')
  },
  {
    //path: '/building/:building_id/poicategories/:category_id/pois',
    path: '/menu/:building_id/room_control/categories/:category_id/pois',
    name: 'roomControlPois',
    component: () => import('../components/RoomControlPois.vue')
  },
  {
    //path: '/building/:building_id/poicategories/:category_id/pois',
    path: '/menu/:building_id/room_control/categories/:category_id/pois/:poi_id',
    name: 'roomControlPoi',
    component: () => import('../components/RoomControlPoi.vue')
  },
  {
    //path: '/building/:building_id/poicategories/:category_id/pois',
    path: '/building/:building_id/:category_id/poi/:poi_id/create_booking',
    name: 'createManageBooking',
    component: () => import('../components/CreateManageBooking.vue')
  },
  {
    path: '/building/:building_id/:category_id/poi/:poi_id/reservation/:reservation_id/appointment/:appointment_id/edit_booking',
    name: 'editBooking',
    component: () => import('../components/CreateManageBooking.vue')
  },
  {
    path: '/building/:building_id/pois/:poi_id',
    name: 'poiDetails',
    component: () => import('../components/PoiDetails.vue')
  },
  {
    path: '/bookings',
    name: 'bookings',
    component: () => import('../components/Bookings.vue'),
    children: [{
      path: '/bookings/#',
      name: 'bookings-list',
      component: () => import('../components/BookingsList.vue')
    }]
  },
 
 
  {
    path: '/incidentreports',
    name: 'incidentReports',
    component: () => import('../components/IncidentReports.vue')
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../components/Menu.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
