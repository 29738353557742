<template>
	<v-app id="app" ref="app" :key="$route.path">
		<v-main>
			<v-container fluid>
				<Branding />
				<v-bottom-navigation color="black" style="font-weight: bold" v-model="view" app v-if="showNavigation">
					<v-btn @click="navigateTo('building')" :value="navigationItems.building.view" height="100%">
						<span>{{ navigationItems.building.label }}</span>
						<v-icon>{{ navigationItems.building.icon }}</v-icon>
					</v-btn>
					<v-btn @click="navigateTo('bookings')" :value="navigationItems.bookings.view" height="100%">
						<span>{{ navigationItems.bookings.label }}</span>
						<v-icon>{{ navigationItems.bookings.icon }}</v-icon>
					</v-btn>
					<v-btn  @click="navigateTo('news')" :value="navigationItems.news.view" height="100%">
						<span>{{ navigationItems.news.label }}</span>
						<v-icon>{{ navigationItems.news.icon }}</v-icon>
					</v-btn>
					<v-btn @click="navigateTo('menu')" :value="navigationItems.menu.view" height="100%">
						<span>{{ navigationItems.menu.label }}</span>
						<v-icon>{{ navigationItems.menu.icon }}</v-icon>
					</v-btn>
					<v-btn id="logout" @click="performLogout" height="100%">
						<span style="color: grey">{{ $t("menu.logout") }}</span>
						<v-icon color="grey">mdi-logout</v-icon>
					</v-btn>
				</v-bottom-navigation>
				<v-slide-x-transition mode="out-in">
					<router-view />
				</v-slide-x-transition>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
	import Branding from "@/customer-branding/Branding.vue";
	import PWAPrompt from "vue2-ios-pwa-prompt";

	import { OAuthService } from "@/services/OAuthService";
	import Vue from "vue";
	import { mapActions } from "vuex";
	import Swal from "sweetalert2";

	export default {
		name: "App",
		components: { Branding },
		data() {
			return {
				view: "",
				navigationItems: {
					building: {
						view: "building",
						label: this.$t("office.office"),
						icon: "mdi-office-building-marker-outline",
						route: "building",
					},
					bookings: {
						view: "bookings",
						label: this.$t("booking.bookings"),
						icon: "mdi-calendar-month-outline",
						route: "bookings",
					},
					news: {
						view: "news",
						label: "Info",
						icon: "mdi-newspaper-variant-outline",
						route: "infoCenter",
					},
					menu: {
						view: "menu",
						label: this.$t("menu.menu"),
						icon: "mdi-menu",
						route: "menu",
					},
				},
			};
		},
		computed: {
			showNavigation() {
				const restrictedRoutes = ["no-permission", "login", "/"];
				return !restrictedRoutes.includes(this.$route.name) && !restrictedRoutes.includes(this.$route.fullPath);
			},
			IS_INFOCENTER(){
			 let result = configs?.features?.infocenter;
			 return result;
			}
		},
		mounted() {
			this.syncViewWithRoute();
			
		},
		methods: {
			syncViewWithRoute() {
				const route = this.$route.name;
				for (const key in this.navigationItems) {
					if (this.navigationItems[key].route === route) {
						this.view = this.navigationItems[key].view;
						break;
					}
				}
			},
			async performLogout() {
				Swal.fire({
					icon: "warning",
					title: this.$t("messages.title-logout"),
					text: this.$t("messages.text-are-you-sure-you-want-to-logout-"),
					allowOutsideClick: true,
					confirmButtonColor: "red",
					showCancelButton: true,
					confirmButtonText: "Yes",
					cancelButtonText: "Cancel",
				}).then(async result => {
					if (result.isConfirmed) {
						try {
							const { doLogout } = await import("@/controllers/BackboneController");
							doLogout();
						} catch (error) {
							console.error("Logout error:", error);
						}
					}
				});
			},
			navigateTo(view) {
				if (this.navigationItems[view]) {
					this.view = this.navigationItems[view].view;
					this.$router.replace({ name: this.navigationItems[view].route }).catch(error => {
						console.error("Navigation error:", error);
					});
				}
			},
		},
		watch: {
			"$route"(newRoute) {
				for (const key in this.navigationItems) {
					if (this.navigationItems[key].route === newRoute.name) {
						this.view = this.navigationItems[key].view;
						console.log("Matched Nav Item:", this.navigationItems[key]);
						break;
					}
				}
			},
		},
	};
</script>

<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}
</style>
